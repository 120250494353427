'use strict';

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Layout from '../components/Layout';

import { graphql } from 'gatsby';

const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
`;

const NotFoundCard = styled.section`
    width: 27rem;
    padding: 1.6rem;
`;

const NotFoundEmoji = styled.h2`
    font-size: 6rem;
`;

const NotFoundText = styled.p``;

export default ({ data }) => (
    <Layout currentPage="404" data={data}>
        <Helmet>
            <title>404</title>
            <meta
                name="description"
                content="Oh, no! Nothing interesting here. Wrong page, maybe? 😭"
            />
        </Helmet>

        <NotFoundContainer>
            <NotFoundCard>
                <NotFoundEmoji>😭</NotFoundEmoji>
                <NotFoundText>
                    Oh, no! Nothing interesting here.
                    <br />
                    Wrong page, maybe?
                </NotFoundText>
            </NotFoundCard>
        </NotFoundContainer>
    </Layout>
);

export const query = graphql`
    query NotFoundQuery {
        site {
            ...FooterFragment
        }
    }
`;
